<template>
  <div>
    <chart-card :chart-data="registrationsOverTimeChart.data"
                :chart-height="150"
                chart-id="recent-monthly-registrations-chart"
                chart-title="RECENT MONTHLY REGISTRATIONS">
      <span slot="title">Monthly Registrations</span>
      <!-- <badge slot="title-label" type="success">+18%</badge> -->

      <template slot="footer-title">{{ registrationsOverTimeChart.total }} total registrations over past {{ registrationsOverTimeChart.data.labels.length-1 }} months</template>

      <!-- <p-button slot="footer-right" aria-label="add button" type="success" round icon size="sm">
        <i class="nc-icon nc-simple-add"></i>
      </p-button> -->
    </chart-card>
    <!-- <chart-card :chart-data="validationsOverTimeChart.data" :chart-options="validationsOverTimeChart.options" :key="chartKey">
      <span slot="title">{{ validationsOverTimeChart.data.total }}</span>
      <h6 slot="subtitle">
        Brand validations over the last 6 months
      </h6>
    </chart-card> -->
  </div>
</template>
<script>
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard'
  import StatsService from 'src/services/stats.service'
  import { uuid } from 'vue-uuid'

  export default{
    props: {
      brand: {
        type: Object,
        required: true
      }
    },
    components: {
      ChartCard
    },
    data () {
      return {
        chartKey: uuid.v4(),
        registrationsOverTimeChart: {
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            series: [0, 0, 0, 0, 0, 0, 0]
          },
          total: 0,
          percentChange: 0,
          options: {
            showPoint: false,
            lineSmooth: true,
            height: '210px',
            axisX: {
              showGrid: false,
              showLabel: true
            },
            axisY: {
              offset: 40,
              showGrid: false
            },
            low: 0,
            high: 'auto',
            classNames: {
              line: 'ct-line ct-green'
            }
          }
        }
      }
    },
    methods: {
      init () {
        this.getRecentMonthlyRegistrationsData()
      },
      async getRecentMonthlyRegistrationsData () {
        let response = await StatsService.getRecentMonthlyRegistrations(this.brand.id)
        this.registrationsOverTimeChart.data.labels = response.data.months
        this.registrationsOverTimeChart.data.series = response.data.values
        this.registrationsOverTimeChart.total = response.data.total
        this.chartKey = uuid.v4()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      brand: function (newUser, oldUser) {
        // if active brand is changed, then user will update
        // so we know to get stats again (for active brand)
        this.init()
      }
    }
  }
</script>
<style>
</style>
