<template>
  <div>
    <div style="text-align: center; margin-top: 40px;">
      <h2>Authentic.net Scanner</h2>
    </div>
    <div class="col-md-4 ml-auto mr-auto">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
      <div class="text-center" style="padding-top:20px;">
        <p>*Please scan using a mobile device</p>
        <router-link to="/authenticate" class="btn">
          Enter Code Manually
        </router-link>
        <a href="https://authentic.net" class="btn">Authentic.net Home</a>
      </div>
      <!-- <p-button native-type="submit" slot="footer" type="primary" v-if="!loading" round block class="mb-3">Submit</p-button>
      <p-button native-type="submit" slot="footer" type="primary" v-if="loading" round block class="mb-3" disabled="disabled">
        <span class="btn-label">
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </span>
        Submit
      </p-button> -->
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  data () {
    return {
    }
  },

  components: { QrcodeStream },

  methods: {
    onDecode (content) {
      console.log('on decode: ' + content)
      // let params = new URLSearchParams(content)
      if (content.includes('scan.authentic.net')) {
        let x = content.split('/')
        let code = x[x.length - 1]
        console.log(code)
        this.$router.push({ name: 'Authenticate', params: { code: code } })
      } else if (content.includes('authentic.net')) {
        let x = content.split('=')
        let code = x[x.length - 1]
        console.log(code)
        this.$router.push({ name: 'Authenticate', params: { code: code } })
      } else {
        let x = content.split('=')
        let code = x[x.length - 1]
        console.log(code)
        this.$router.push({ name: 'Authenticate', params: { code: code } })
      }
    },
    onLocate (points) {
      // console.log('on locate: ' + points)
    }
  }
}
</script>

<style>

</style>