<template>
  <div ref="mapContainer" id="worldMap" style="height: 300px;"></div>
</template>
<script>
  import 'd3';
  import * as d3 from 'd3';
  import 'topojson';
  import DataMap from 'datamaps'
  import { throttle } from "src/util/throttle";
  import { countryIso2to3 } from 'src/util/countryIso2to3'

  export default {
    props: {
      statsArray: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        color1: '#8cf2ea',
        color2: '#18DECC',
        highlightFillColor: '#112448',
        highlightBorderColor: '#432989',
        mapData: {
          'AUS': 760,
          'BRA': 550,
          'CAN': 120,
          'DEU': 1300,
          'FRA': 540,
          'GBR': 690,
          'GEO': 200,
          'IND': 200,
          'ROU': 600,
          'RUS': 300,
          'USA': 2920
        }
      }
    },
    methods: {
      generateColors(length) {
        return d3.scaleLinear()
          .domain([0, length])
          .range([this.color1, this.color2]);
      },
      generateMapColors() {
        let mapDataValues = Object.values(this.mapData);
        let maxVal = Math.max(...mapDataValues);
        let colors = this.generateColors(maxVal);
        let mapData = {};
        let fills = {
          defaultFill: "#e4e4e4",
        };
        for (let key in this.mapData) {
          let val = this.mapData[key];
          fills[key] = colors(val);
          mapData[key] = {
            fillKey: key,
            value: val
          };
        }
        return {
          mapData,
          fills
        }
      },
      initVectorMap() {
        this.mapData = []
        for (let i = 0; i < this.statsArray.length; i++) {
          // console.log('country code and qty', this.statsArray[i].country_code, countryIso2to3(this.statsArray[i].country_code), this.statsArray[i].qty)
          this.mapData[countryIso2to3(this.statsArray[i].country_code)] = this.statsArray[i].qty
        }
        let { fills, mapData } = this.generateMapColors();
        let worldMap = new DataMap({
          scope: 'world',
          element: this.$refs.mapContainer,
          fills,
          data: mapData,
          responsive: true,
          geographyConfig: {
            borderWidth: 0.5,
            borderOpacity: 0.8,
            highlightFillColor: this.highlightFillColor,
            highlightBorderColor: this.highlightBorderColor,
            highlightBorderWidth: 0.5,
            highlightBorderOpacity: 0.8
          },
        });
        let resizeFunc = worldMap.resize.bind(worldMap);
        window.addEventListener('resize', () => {
          throttle(resizeFunc, 40)
        }, false);
      }
    },
    async mounted() {
      this.initVectorMap()
    }
  }
</script>
<style>
</style>
