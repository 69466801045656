import Api from 'src/apis/api'

class SubscriptionService {
  put (newPlanId) {
    let data = {
      new_plan_id: newPlanId
    }

    return Api.post('/api/account/change-subscription', data)
  }
}

export default new SubscriptionService()
