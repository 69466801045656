<template>
  <div>
    <div class="row" id="plan-header-container">
      <h1>Change Your Authentic.net Plan Anytime</h1>
      <p>The best solution for protecting your brand and gaining trust from your customers, built to scale. Upgrade as you grow.</p>
    </div>
    <div class="row" ref="plans">
      <div class="col-md-4">
        <div class="card plan-card not-featured-plan">
          <div class="card-header">
            <h3 class="card-title">Business Startup 500</h3>
            <p class="category">$49.99 PER MONTH</p>
          </div>
          <button v-if="showActivatePlanBtn(0)" class="btn btn-success btn-round" @click.prevent="selectPlan(0)">Activate plan</button>
          <button v-if="showDowngradeDisabledBtn(0)" class="btn btn-default btn-round" disabled="disabled">Downgrade Disabled</button>
          <button v-if="showCurrentPlanBtn(0)" class="btn btn-default btn-round" disabled="disabled">Current plan</button>
          <button type="button" v-if="!showActivatePlanBtn(0) && !showCurrentPlanBtn(0) && !showDowngradeDisabledBtn(0)" class="btn btn-success btn-round" disabled="disabled">
            <span class="btn-label">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            Activate plan
          </button>
          <div class="no-cc-required">No credit card required</div>
          <div class="card-content">
            <div class="plan-desc"><h6>Business startup essentials</h6></div>
            <h6 class="plan-inc">Includes:</h6>
            <ul>
              <li><div>Protect products with 500 Authentic labels per month</div></li>
              <li><div>Digital Dashboard</div></li>
              <li><div>Amazon.com + 1 additional Retailer / Sales Channel</div></li>
              <li><div>1 User</div></li>
              <li><div>1 Brand</div></li>
              <li><div>Dymo Printer Integration</div></li>
              <li><div>FBA / Marketplace label printing</div></li>
              <li><div>Unlimited Products / Variations</div></li>
              <li><div>Ideal for Small Business Startups</div></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card plan-card featured-plan">
          <div class="card-header">
            <h2 class="card-title">Small Business 2000</h2>
            <p class="category">$89.99 PER MONTH</p>
          </div>
          <button v-if="showActivatePlanBtn(1)" class="btn btn-success btn-round" @click.prevent="selectPlan(1)">Activate plan</button>
          <button v-if="showDowngradeDisabledBtn(1)" class="btn btn-default btn-round" disabled="disabled">Downgrade Disabled</button>
          <button v-if="showCurrentPlanBtn(1)" class="btn btn-default btn-round" disabled="disabled">Current plan</button>
          <button type="button" v-if="!showActivatePlanBtn(1) && !showCurrentPlanBtn(1) && !showDowngradeDisabledBtn(1)" class="btn btn-success btn-round" disabled="disabled">
            <span class="btn-label">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            Activate plan
          </button>
          <div class="no-cc-required">No credit card required</div>
          <div class="card-content">
            <div class="plan-desc"><h6>Flexibility for growth</h6></div>
            <h6 class="plan-inc">Includes:</h6>
            <ul>
              <li><div>Protect products with 2000 Authentic labels per month</div></li>
              <li><div>Digital Dashboard</div></li>
              <li><div>All Amazon + 2 additional Retailers / Sales Channels</div></li>
              <li><div>2 Users</div></li>
              <li><div>2 Brands</div></li>
              <li><div>Dymo Printer Integration</div></li>
              <li><div>FBA / Marketplace label printing</div></li>
              <li><div>Unlimited Products / Variations</div></li>
              <li><div>Enhanced security &amp; encryption</div></li>
              <li><div>Perfect for the growing Small Business</div></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card plan-card not-featured-plan">
          <div class="card-header">
            <h3 class="card-title">Growing Professional 8000</h3>
            <p class="category">$199 PER MONTH</p>
          </div>
          <button v-if="showActivatePlanBtn(2)" class="btn btn-success btn-round" @click.prevent="selectPlan(2)">Activate plan</button>
          <button v-if="showDowngradeDisabledBtn(2)" class="btn btn-default btn-round" disabled="disabled">Downgrade Disabled</button>
          <button v-if="showCurrentPlanBtn(2)" class="btn btn-default btn-round" disabled="disabled">Current plan</button>
          <button type="button" v-if="!showActivatePlanBtn(2) && !showCurrentPlanBtn(2) && !showDowngradeDisabledBtn(2)" class="btn btn-success btn-round" disabled="disabled">
            <span class="btn-label">
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </span>
            Activate plan
          </button>
          <div class="no-cc-required">No credit card required</div>
          <div class="card-content">
            <div class="plan-desc"><h6>Stability at scale</h6></div>
            <h6 class="plan-inc">Includes:</h6>
            <ul>
              <li><div>Protect products with 8000 Authentic labels per month</div></li>
              <li><div>Digital Dashboard</div></li>
              <li><div>All Amazon + 4 additional Retailers / Sales Channels</div></li>
              <li><div>4 Users</div></li>
              <li><div>4 Brands</div></li>
              <li><div>Dymo Printer Integration</div></li>
              <li><div>FBA / Marketplace label printing</div></li>
              <li><div>Unlimited Products / Variations</div></li>
              <li><div>Enhanced security &amp; encryption</div></li>
              <li><div>Digital Branded Landing Page</div></li>
              <li><div>Digital Registration Warranty Page</div></li>
              <li><div>Perfect for the growing Small Business</div></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row plans-footer">
      <p>Contact us for entrprise level plans and pricing, for customized add-ons, or if you need to downgrade your plan.</p>
      <p v-if="isActive()">Need to cancel your plan? <a href="#" @click.prevent="confirmCancel()">Click here</a>.</p>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import SubscriptionService from 'src/services/subscription.service'
  import swal from 'sweetalert2'

  export default{
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      activeBrand () {
        return this.user.account.brands[_.findIndex(this.user.account.brands, b => { return b.id === this.user.options.active_brand_id })]
      },
      activePlanId () {
        if (this.user.subscription == null) return ''
        return this.user.subscription.stripe_plan
      },
      activePlanIndex () {
        for (var i = 0; i < this.plans.length; i++) {
          if (this.plans[i].id == this.activePlanId) return i
        }
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        loading: false,
        plans: [
          {
            loading: false,
            id: process.env.VUE_APP_STRIPE_PLAN_1,
            name: 'Business Startup 500'
          },
          {
            loading: false,
            id: process.env.VUE_APP_STRIPE_PLAN_2,
            name: 'Small Business 2000'
          },
          {
            loading: false,
            id: process.env.VUE_APP_STRIPE_PLAN_3,
            name: 'Growing Professional 8000'
          }
        ]
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      async init () {
        console.log(this.user)
      },
      isCurrentPlan (planIndex) {
        return this.plans[planIndex].id === this.activePlanId
      },
      async selectPlan (planIndex) {
        if (planIndex < this.activePlanIndex) {
          this.showNotify('Please contact support if you wish to downgrade your plan.', 'danger', 'Failed')
        } else if (this.activePlanId !== '') {
          await this.changePlan(planIndex)
        } else {
          await this.activatePlan(planIndex)
        }
      },
      async activatePlan (planIndex) {
        // this.plans[planIndex].loading = true
        // let userToken = await firebase.auth().currentUser.getIdToken()
        // let data = {
        //   userToken: userToken,
        //   stripePlanId: this.plans[planIndex].id,
        //   planName: this.plans[planIndex].name
        // }
        // let activatePlan = firebase.functions().httpsCallable('activatePlan')
        // activatePlan(data)
        // .then(result => {
        //   this.showNotify('Your plan was changed successfully.', 'success', 'Success')
        //   this.init()
        //   EventBus.$emit('auth-refreshed')
        //   this.plans[planIndex].loading = false
        //   this.$router.replace({ name: 'Account' })
        // })
        // .catch(err => {
        //   swal.fire({
        //     title: 'Error Changing Plan',
        //     text: err.message,
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-info btn-fill',
        //     buttonsStyling: false
        //   })
        //   this.plans[planIndex].loading = false
        // })
      },
      async changePlan (planIndex) {
        this.plans[planIndex].loading = true
        try {
          let response = await SubscriptionService.put(this.plans[planIndex].id)
          this.showNotify('Your plan was changed successfully.', 'success', 'Success')
          this.plans[planIndex].loading = false
          this.me()
          this.$router.replace({ name: 'Profile' })
        } catch (err) {
          swal.fire({
            title: 'Error Changing Plan',
            text: err.message,
            type: 'error',
            confirmButtonClass: 'btn btn-info btn-fill',
            buttonsStyling: false
          })
          this.plans[planIndex].loading = false
        }
      },
      confirmCancel () {
        // swal.fire({
        //   title: 'Are you sure?',
        //   text: `Cancelling will leave your brand unprotected.`,
        //   type: 'warning',
        //   showCancelButton: true,
        //   confirmButtonClass: 'btn btn-success btn-fill',
        //   cancelButtonClass: 'btn btn-danger btn-fill',
        //   confirmButtonText: 'Yes, I am sure!',
        //   buttonsStyling: false
        // }).then(() => {
        //   this.cancel()
        // })
      },
      async cancel () {
        // let userToken = await firebase.auth().currentUser.getIdToken()
        // let data = {
        //   userToken: userToken
        // }
        // let cancelPlan = firebase.functions().httpsCallable('cancelPlan')
        // cancelPlan(data)
        // .then(result => {
        //   this.showNotify('Your plan was cancelled successfully.', 'success', 'Success')
        //   this.$router.replace({ name: 'Account' })
        // })
        // .catch(err => {
        //   swal.fire({
        //     title: 'Error Cancelling Plan',
        //     text: err.message,
        //     type: 'error',
        //     confirmButtonClass: 'btn btn-info btn-fill',
        //     buttonsStyling: false
        //   })
        // })
      },
      isUpgrade (planIndex) {
        return planIndex > this.activePlanIndex
      },
      isActive () {
        return !this.loading && true
      },
      showActivatePlanBtn (planIndex) {
        return !this.loading && !this.plans[planIndex].loading && this.isUpgrade(planIndex) && (!this.isCurrentPlan(planIndex) || !this.isActive())
      },
      showDowngradeDisabledBtn (planIndex) {
        return !this.loading && !this.plans[planIndex].loading && !this.isCurrentPlan(planIndex) && !this.isUpgrade(planIndex)
      },
      showCurrentPlanBtn (planIndex) {
        return !this.loading && !this.plans[planIndex].loading && this.isCurrentPlan(planIndex) && this.isActive()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
#plan-header-container {
  text-align: center;
  justify-content: center;
  padding-bottom: 50px;
}
.plan-card {
  text-align: center;
  justify-content: center;
}
.plan-card .btn {
  width: 80%;
  margin: 0px auto;
}
.plan-card .no-cc-required {
  color: #9A9A9A;
  display: none;
}
.plan-card .plan-desc {
  border-bottom: 1px solid #dee1e1;
}
.plan-card .plan-desc h6 {
  text-transform: none;
}
.plan-card .plan-inc {
  padding-top: 10px;
  padding-bottom: 10px;
}
.plan-card ul {
  list-style-type: none;
  padding-left: 0;
}
.plan-card ul li div {
  padding-top: 10px;
  padding-bottom: 10px;
}
.plan-card ul li:before {
  display: block;
  width: 50%;
  height: 0;
  margin: .44444rem auto;
  border-top: 1px solid #dee1e1;
  content: "";
}
.featured-plan {
    -webkit-box-shadow: 0 0 28px rgba(22,32,32,.25), 0 10px 10px rgba(22,32,32,.22);
    box-shadow: 0 0 28px rgba(22,32,32,.25), 0 10px 10px rgba(22,32,32,.22);
    padding-top: 3.11111rem;
    padding-bottom: 3.11111rem;
}
.not-featured-plan {
  margin-top: 3.11111rem;
  margin-bottom: 3.11111rem;
}
.plans-footer {
  margin-top: 40px;
  text-align: center;
}
.stripe-card {
  width: 400px;
  margin: 0 auto;
}
.stripe-card base {
  color:lawngreen;
  font-family: inherit !important;
}
.stripe-card.complete {
  border-color: green;
}
</style>
